import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';

import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';
import SimpleMenu from '../../SimpleMenu';

const styles = (theme) => ({
  root: {
    display: 'flex',
    // flexBasis: 'column',
    justifyContent: 'center',
  },
});

class SelectField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    initialValue: PropTypes.any,
    schemaExtensions: PropTypes.object,
    disabled: PropTypes.bool,
    filterOptions: PropTypes.func,
    trigger: PropTypes.node,
  }

  static defaultProps = {
    helperText: undefined,
    label: undefined,
    multiple: false,
    initialValue: undefined,
    schemaExtensions: undefined,
    disabled: false,
    options: undefined,
    filterOptions: undefined,
    trigger: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, { initialValue: props.initialValue, schemaExtensions: props.schemaExtensions });
  }

  handleChange = (value) => {
    const { onChange } = this.context;
    const { name, onChange: propsOnChange } = this.props;

    onChange(name, value);
    if (propsOnChange) propsOnChange(value, name);
  }

  valueToPrimary = (value) => {
    const { name, schemaExtensions } = this.props;
    const { type, schema } = this.context;
    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const options = this.props.options || schemaField.options || [];
    const option = options.find((o) => o.value === value);
    const primary = option ? (option.primary || option.label) : '';
    if (!primary) {
      console.error(`<SelectField> could not find option for value "${value}" on "${type}.${name}"`);
    }
    return primary;
  }

  renderValue = (value) => {
    const { multiple } = this.props;

    if (multiple) return value.map(this.valueToPrimary).join(', ');

    return this.valueToPrimary(value);
  }

  render() {
    const { name, classes, filterOptions, trigger, helperText, label: propsLabel, multiple, options: propsOptions, schemaExtensions, disabled } = this.props;
    const { getFieldValue, getFieldErrors, pendingMutation, schema, type } = this.context;
    let value = getFieldValue(name);
    const errors = getFieldErrors(name);

    const schemaField = schema.types[type].fields[name] || schemaExtensions.types[type].fields[name];
    const label = propsLabel === '' ? '' : propsLabel || schemaField.label;

    let options = propsOptions || schemaField.options;
    if (!options) {
      console.error(`No options supplied for <SelectField> for ${type}.${name}`);
      options = [];
    }
    options = options.filter((o) => !!o);
    if (filterOptions) options = options.filter(filterOptions);

    if (value === undefined || value === null) value = '';
    if (multiple && !Array.isArray(value)) value = [];

    const valueOption = multiple && options ? null : options.find((o) => o.value === value);

    if (trigger) {
      return (
        <SimpleMenu
          items={options}
          onChange={(option) => this.handleChange(option.value)}
          trigger={trigger}
        />
      );
    }

    return (
      <FormControl error={!!errors} disabled={disabled || pendingMutation} className={classes.root} variant="outlined" required={schemaField.validation.required}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={(event) => this.handleChange(event.target.value)}
          multiple={multiple}
          renderValue={this.renderValue}
          label={label}
          variant="outlined"
          style={{ backgroundColor: valueOption ? valueOption.color : undefined }}
        >
          {options.map((o) => (
            <MenuItem key={o.value || o.label || o.primary} value={o.value} style={{ backgroundColor: o.color }}>
              {multiple ? <Checkbox checked={value.includes(o.value)} /> : null}
              <ListItemText primary={o.primary || o.label} secondary={o.secondary} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

SelectField = _.flow(
  withStyles(styles),
)(SelectField);

export default SelectField;
