import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Stop as StopIcon, Add as AddIcon } from '@material-ui/icons';
import BeachIcon from 'mdi-material-ui/Beach';
import classNames from 'classnames';
import { startOfToday, formatISO } from 'date-fns';
import { useNodes } from 'src/kiska/hooks/useNode';
import { useHistory } from 'react-router-dom';
import { FloatingButton } from 'src/components/FloatingButton';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import defaultAvatar from './defaultAvatar.png';
import DurationTicker from './DurationTicker';
import { UserUpdateForm } from './UserUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    width: '100%',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    position: 'relative',
  },
  card: {
    width: `250px !important`,
    margin: theme.spacing(1, 1),
    '&.never-worked': {
      opacity: 0.3,
    },
  },
  media: {
    height: 150,
    backgroundSize: 'cover',
  },
  content: {
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  jobContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  jobTicker: {
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  totalTicker: {
    fontSize: 18,
    color: '#FFF',
    // color: theme.palette.text.secondary,
  },
  title: {
    flex: 1,
    lineHeight: 1,
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#FFF',
    '&.working': {
      backgroundColor: green[300],
    },
    '&.worked': {
      backgroundColor: red[200],
    },
    '&.never-worked': {
      color: theme.palette.text.primary,
    },
  },
  name: {
    lineHeight: 1.2,
  },
  jobTitle: {
    overflow: 'hidden',
    lineHeight: 1,
    marginRight: theme.spacing(0.5),
  },
  notWorkingText: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const WorkerBoard = ({ onLogin }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user: currentUser } = useUser();
  const rootRef = useRef();

  const { nodes } = useNodes({
    type: 'user',
    orderBy: [{ displayName: 'asc' }],
    where: {
      roleId: { _neq: 'admin' },
      _not: { options: { _contains: { systemAccount: true } } },
    },
    selections: `
      id displayName
      avatar {id url mimeType name }
      role { id label }
      options updatedAt createdAt

      openWorkEntries: workEntries(where: {end: {_is_null: true}}){
        id start startTime end endTime notes
        job {id shortId title specs}
      }

      completedWorkEntries: workEntries_aggregate(where: {
        start: { _gte: "${formatISO(startOfToday())}" }
      }){
        aggregate{
          jobs: count(columns: jobId, distinct: true)
          sessions: count(columns: id, distinct: true)
          sum{duration}
        }
      }
    `,
  });

  const handleClick = (user) => () => {
    history.push(`/app/users/${user.id}/work-log`);
  };

  const renderUser = (user) => {
    const isWorking = !!user.openWorkEntries.length;
    const completedDuration = user.completedWorkEntries.aggregate.sum.duration;
    const hasWorked = !!completedDuration;
    const status = isWorking ? 'working' : (hasWorked ? 'worked' : 'never-worked');
    let workEntry;
    let job;
    const cardClassName = classNames(classes.card, status);
    const titleClassName = classNames(classes.title, status);
    const contentClassName = classNames(classes.content, status);

    if (isWorking) {
      workEntry = user.openWorkEntries[0];
      job = workEntry.job;
    }

    const avatar = user.avatar ? user.avatar.url : defaultAvatar;

    return (
      <Card key={user.id} className={cardClassName}>
        <CardActionArea onClick={handleClick(user)}>

          <CardContent className={titleClassName}>
            <Typography variant="h6" align="center" className={classes.name}>
              {user.displayName}
            </Typography>
            {status === 'working' && (
              <DurationTicker
                className={classes.totalTicker}
                start={workEntry.start}
                end={workEntry.end}
                includeSeconds
                fixture={completedDuration}
              />
            )}
            {status === 'has-worked' && (
              <DurationTicker
                className={classes.totalTicker}
                includeSeconds
                fixture={completedDuration}
              />
            )}
          </CardContent>

          <CardMedia className={classes.media} image={avatar} title="Avatar" />

          <CardContent className={contentClassName}>
            {status === 'working' && (
              <>
                <div className={classes.jobContent}>
                  {/* <Typography variant="body2" color="textSecondary" className={classes.shortId}>{job.shortId}</Typography> */}
                  <Typography variant="body2" align="left" className={classes.jobTitle}>{job.title}</Typography>
                  <DurationTicker
                    className={classes.jobTicker}
                    start={workEntry.start}
                    end={workEntry.end}
                    includeSeconds
                  />
                </div>
              </>
            )}
            {status === 'worked' && (
              <Typography variant="h6" color="textSecondary" className={classes.jobContent}>
                <StopIcon />&nbsp;&nbsp;Idle
              </Typography>
            )}

            {status === 'never-worked' && (
              <Typography variant="h6" color="textSecondary" className={classes.jobContent}>
                <BeachIcon />&nbsp;&nbsp;No work today
              </Typography>
            )}
          </CardContent>

        </CardActionArea>
      </Card>
    );
  };

  return (
    <div ref={rootRef} className={classes.root}>
      {!!(nodes && nodes.length) && nodes.map(renderUser)}
      {currentUser.permissions.canInsertUser() && (
        <UserUpdateForm
          trigger={(
            <FloatingButton variant="extended" color="primary" containerRef={rootRef} style={{ width: 'auto' }}>
              <AddIcon /><span>Add Worker</span>
            </FloatingButton>
          )}
        />
      )}
    </div>
  );
};

WorkerBoard.propTypes = {
  onLogin: PropTypes.func,
};
WorkerBoard.defaultProps = {
  onLogin: undefined,
};

export { WorkerBoard };
